<template>
  <div class="page-main-bg">
    <v-container class="main-container team-container position-relative">
      <!--tool-bar-->
      <tool-bar v-bind="toolbarProps">
        <slot />
      </tool-bar>

      <!--filters-->
      <Filters @filter="setFilters" @sortChanged="setFilters" />

      <!--Loader-->
      <loader v-if="isPageLoading" v-bind="loaderProps" class="mt-2" />

      <!-- No price offer -->
      <no-data v-else-if="!priceOffers.length" v-bind="noDataProps" />

      <!-- Price Offer Card -->
      <PriceOfferCard
        v-else
        v-for="priceOffer in priceOffers"
        :key="priceOffer._id"
        v-bind="{ quotation: priceOffer, contractIntervals }"
        @showDetails="openDetails"
        @openOfferModel="draftHandler"
        @cancelled="cancelOfferHandler"
        @openPDF="openPDF"
        class="set__hover"
      />

      <!-- Price offer details -->
      <PriceOfferDetails
        v-if="sideDetailsStatus && !isCustomer"
        :quotation="quotation"
        @closeDetails="sideDetailsStatus = !sideDetailsStatus"
        @openOfferModel="draftHandler"
        @accept="updateOfferStatus"
        @reject="updateOfferStatus"
        @openPDF="openPDF"
        @cancelled="updateOfferStatus"
      />

      <!-------FastActions---------->
      <div class="d-flex align-center justify-end ml-md-8 fast-actions">
        <v-btn
          v-if="!isCustomer"
          color="primary"
          large
          class="font-weight-bold fast-actions-btn pa-5 font-12"
          @click="openAddDialog"
        >
          <img
            src="@/assets/img/svgs/001-writing.svg"
            class="mx-2"
            loading="lazy"
          />
          <span class="mb-1">إضافة عرض</span>
        </v-btn>

        <!----------------------------->
        <!-------Fast actions---------->
        <!----------------------------->
        <fastActions />
      </div>

      <!-- Pagination-->
      <div class="text-center mt-6 mb-5" v-if="forPagination">
        <Pagination :pagination="pagination" @onChange="allQuotations()" />
      </div>

      <!-- Add price offer -->
      <AddPriceOffer
        v-if="showPriceOfferModal"
        v-bind="addPriceOfferProps"
        @closePriceOfferModal="closePriceOfferModal"
        @resetRelaestate="resetRelaestate"
        @refreshData="allQuotations()"
      />

      <!-- Generate PDF -->
      <PDF
        v-if="PDFStatus"
        :quotation="quotation"
        @closePDF="PDFStatus = !PDFStatus"
      />
    </v-container>
  </div>
</template>

<script>
import fastActions from '@/components/helper/fastActions'
import loader from '@/components/helper/loader.vue'
import noData from '@/components/helper/noData.vue'
import Pagination from '@/components/helper/pagination.vue'
import ToolBar from '@/components/listing/ToolBar'
import Filters from '@/components/priceOffers/Filters.vue'
import PDF from '@/components/priceOffers/PDF/PDF.vue'
import PriceOfferCard from '@/components/priceOffers/PriceOfferCard.vue'
import PriceOfferDetails from '@/components/priceOffers/sideDetails'
import confirmationMixin from '@/mixins/confirmationMixin.js'
import { realEstateService } from '@/services'
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'PriceQuotations',
  components: {
    fastActions,
    ToolBar,
    PriceOfferCard,
    Pagination,
    noData,
    loader,
    Filters,
    PriceOfferDetails,
    PDF,
    AddPriceOffer: () =>
      import('@/components/modals/priceOffers/AddPriceOfferModal')
  },
  mixins: [confirmationMixin],
  data() {
    return {
      sideDetailsStatus: false,
      pagination: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 5,
        count: 0
      },
      filters: {
        type: '',
        user: '',
        date: '',
        sort: 'Newest'
      },
      noContract: require('@/assets/img/svgs/contract/noContracts.svg'),
      breadcrumbs: [
        {
          text: 'عروض الإسعار',
          disabled: false,
          exact: true,
          link: true,
          to: { path: '/dashboard/price-offers' }
        }
      ],
      sideDetailes: false,
      isPageLoading: false,
      priceOffers: [],
      showPriceOfferModal: false,
      contractIntervals: [],
      updatedPriceOffer: {},
      quotation: {},
      statusName: {
        accepted: 'قبول',
        rejected: 'رفض',
        cancelled: 'إلغاء'
      },
      statusNow: '',
      PDFStatus: false,
      fromCard: false
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user']),
    ...mapGetters('accountState', ['isCustomer']),
    hasPermission() {
      return this.$hasPermission('properties', 'contracts', 'add')
    },
    noDataProps() {
      return {
        noDataIcon: require('@/assets/img/priceQuotation/priceQuotation.svg'),
        noDataText: 'لا توجد عروض أسعار مضافة حتى الان',
        subTitleStatus: false
      }
    },
    addPriceOfferProps() {
      return {
        priceOffertModalState: this.showPriceOfferModal,
        contractIntervals: this.contractIntervals,
        updatedPriceOffer: this.updatedPriceOffer
      }
    },
    loaderProps() {
      return {
        numberOfLines: 5,
        laoderClasses: 'my-2 shadow-border-radius pa-0',
        type: 'list-item-avatar-two-line',
        cols: 12
      }
    },
    toolbarProps() {
      return {
        breadcrumbs: this.breadcrumbs,
        title: 'عروض الإسعار',
        subTilte: 'عروض الإسعار',
        length: this.priceOffers?.length
      }
    },
    queryManger() {
      let query = `pageSize=${this.pagination.pageSize}&pageNumber=${this.pagination.pageNumber}&sort=${this.filters.sort}`

      if (this.isCustomer) {
        query += `&client=${this.user._id}`
      }

      if (this.filters.user) {
        query += `&clientName=${this.filters.user}`
      }

      if (this.filters.type) {
        query += `&status=${this.filters.type}`
      }

      if (this.filters.date) {
        query += `&createdAt=${this.filters.date}`
      }

      return query
    },
    forPagination() {
      return this.pagination.pagesCount > 1 && !this.isPageLoading
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    openAddDialog() {
      if (!this.$hasPermission('realestates', 'quotations', 'add')) {
        this.addNotification({
          text: 'ليس لديك صلاحية لإضافة عرض سعر',
          color: 'error'
        })
        return
      }
      this.showPriceOfferModal = true
    },
    resetRelaestate() {
      delete this.updatedPriceOffer.realEstate
    },
    setFilters(filter) {
      this.filters[filter.type] = filter.payload
      this.pagination.pageNumber = 1
      this.allQuotations()
    },
    async confirmAlertForUpdateStatus(status) {
      return await this.$root.$confirm.open({
        title: `${status} العرض`,
        message: `هل انت متاكد من ${status} العرض ؟`,
        confirmTxt: 'تاكيد',
        cancelTxt: 'إلغاء',
        options: {
          color: 'danger',
          width: 400,
          zIndex: 200
        }
      })
    },
    async getAttachment() {
      try {
        const { data } = await realEstateService.getAllAttachmentsForQuotation(
          this.updatedPriceOffer?._id,
          `?type=signature`
        )
        if (data?.attachments.length) {
          this.updatedPriceOffer.attachment = data.attachments[0]
        }
      } catch {
        this.addNotification({
          text: 'حدثت مشكلة في المرفقات',
          color: 'error'
        })
      }
    },
    cancelOfferHandler({ status, quotation }) {
      this.fromCard = true
      this.quotation = quotation
      this.updateOfferStatus(status)
    },
    async updateOfferStatus(status) {
      this.statusNow = status
      if (
        !this.$hasPermission('realestates', 'quotations', 'delete') ||
        !this.$hasPermission('realestates', 'quotations', 'update')
      ) {
        this.addNotification({
          text: `ليس لديك صلاحية ${this.statusName[status]} عرض سعر`,
          color: 'error'
        })
        return
      }

      const ifStatus = status === 'cancelled' || status === 'rejected'

      if (ifStatus) {
        this.sideDetailsStatus = false
      }

      if (
        ifStatus &&
        !(await this.confirmAlertForUpdateStatus(this.statusName[status]))
      ) {
        if (!this.fromCard) {
          this.sideDetailsStatus = true
        }
        this.fromCard = false
        return
      }

      await this.updateQuotation(status)
    },
    async updateQuotationWithCustomer(status) {
      try {
        await realEstateService.updateQuotationWithCustomer(
          this.quotation._id,
          {
            environment: this.currentEnv._id,
            status
          }
        )

        this.allQuotations()
        this.addNotification({
          text: `تم ${this.statusName[this.statusNow]} العرض بنجاح`,
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'حدث خطا ما يرجى المحاولة لاحقا',
          color: 'error'
        })
      }
    },
    async updateQuotation(status) {
      try {
        const { data } = await realEstateService.updateQuotation(
          this.quotation._id,
          {
            environment: this.currentEnv._id,
            status
          }
        )
        if (data.quotation?.needsApproval) {
          this.showConfirmationPopupMixin()
        }
        this.allQuotations()
        this.addNotification({
          text: `تم ${this.statusName[this.statusNow]} العرض بنجاح`,
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'حدث خطا ما يرجى المحاولة لاحقا',
          color: 'error'
        })
      }
    },
    draftHandler({ quotation }) {
      if (!this.$hasPermission('realestates', 'quotations', 'update')) {
        this.addNotification({
          text: 'ليس لديك صلاحية لنشر عرض سعر',
          color: 'error'
        })
        return
      }
      this.updatedPriceOffer = quotation
      this.sideDetailsStatus = false
      this.showPriceOfferModal = true
    },
    openPDF() {
      // TODO:
      // this.sideDetailsStatus = false
      // this.PDFStatus = true
    },
    openDetails({ quotation }) {
      this.quotation = quotation
      // TODO:
      if (!this.isCustomer) {
        this.sideDetailsStatus = true
        // return
      }
      // this.PDFStatus = true
    },
    async allQuotations() {
      try {
        this.isPageLoading = true
        const res = await realEstateService.allQuotations(
          this.currentEnv._id,
          this.queryManger
        )
        this.pagination.pagesCount = Math.ceil(
          res?.data?.count / this.pagination.pageSize
        )
        this.pagination.count = res?.data?.count ?? 0
        this.priceOffers = res?.data?.quotations ?? []
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل العروض ، يرجى المحاوله مجددا',
          color: 'error'
        })
      } finally {
        this.isPageLoading = false
      }
    },
    closePriceOfferModal() {
      this.showPriceOfferModal = false
      this.updatedPriceOffer = {}
    }
  },
  watch: {
    updatedPriceOffer(val) {
      if (Object.keys(val).length) {
        this.getAttachment()
      }
    }
  },
  created() {
    this.$emit('updateInformativeTip', this.$route.meta?.informativeTip)
    this.allQuotations()
  },
  metaInfo() {
    return {
      title: 'عروض الإسعار'
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/_properties.scss';
@import '@/styles/material-dashboard/_team.scss';
@import '@/styles/material-dashboard/fastactions.scss';
@import '@/styles/material-dashboard/_statistics-cards.scss';
</style>
